<template>
  <div class="pageContol priceManager">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级价格管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input v-model.trim="projectCode" clearable placeholder="请输入班级编码" size="small"></el-input>
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input v-model.trim="projectName" clearable placeholder="请输入班级名称" size="small"></el-input>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select size="small" v-model.trim="compId" remote :remote-method="getCompanyList" filterable clearable
                placeholder="请至少输入两个字搜索">
                <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="是否结算" class="searchboxItem ci-full">
              <span class="itemLabel">是否结算:</span>
              <el-select size="small" v-model="billState" clearable placeholder="请选择是否结算">
                <el-option v-for="item in settlementStatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="设置收款价格" class="searchboxItem ci-full">
              <span class="itemLabel">设置收款价格:</span>
              <el-select size="small" v-model="IsCollectionPrice" placeholder="请选择是否设置收款价格" clearable>
                <el-option label="全部" value=""></el-option>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>
            <div title="设置代理价格" class="searchboxItem ci-full">
              <span class="itemLabel">设置代理价格:</span>
              <el-select size="small" v-model="IsAgencyPrice" placeholder="请选择是否设置代理价格" clearable>
                <el-option label="全部" value=""></el-option>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>

            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader v-model="areaId" :options="areatreeList" :props="propsarea" clearable filterable
                size="small"></el-cascader>
            </div>
            <div title="设置课程方价格" class="searchboxItem ci-full">
              <span class="itemLabel">设置课程方价格:</span>
              <el-select size="small" v-model="IsCoursePrice" placeholder="请选择是否设置课程方价格" clearable>
                <el-option label="全部" value=""></el-option>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="是否有收款合同" class="searchboxItem ci-full">
              <span class="itemLabel">是否有收款合同:</span>
              <el-select size="small" v-model="hasCollectionContract" placeholder="是否有收款合同" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>
            <div title="开班时间" class="searchboxItem ci-full">
              <span class="itemLabel">开班时间:</span>
              <el-date-picker clearable size="small" v-model="startTime" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div class="df" style="padding: 0 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="exportClassPrice()">导出</el-button>
              <el-button type="primary" class="bgc-bv" round @click="setClassPriceMore()">设置价格</el-button>
            </div>
            <!-- <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">培训类型:</span>
              <el-cascader
                size="small"
                :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }"
                v-model="trainTypeId"
                :options="trainTypeList"
                clearable
              ></el-cascader>
            </div> -->
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="tableData" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" row-key="projectId" @selection-change="checkListData" :header-cell-style="tableHeader"
              stripe>
              <el-table-column type="selection" width="50px" align="center" fixed
                :reserve-selection="true"></el-table-column>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="200px"
                fixed />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="200px" />
              <el-table-column label="机构名称" align="left" show-overflow-tooltip prop="compName" min-width="200" />
              <el-table-column label="行政区划" align="left" show-overflow-tooltip prop="areaNamePath" min-width="200" />
              <el-table-column label="培训类型" align="left" show-overflow-tooltip prop="trainTypeNamePath" min-width="160" />
              <el-table-column label="培训等级" align="left" show-overflow-tooltip prop="trainLevelName" />
              <el-table-column label="代理商" align="center" show-overflow-tooltip prop="agencyName" min-width="80" />
              <el-table-column label="是否有收款合同" align="center" show-overflow-tooltip prop="hasCollectionContract"
                min-width="180px" />
              <el-table-column label="是否设置收款价格" align="center" show-overflow-tooltip prop="hasSetPayeePrice"
                min-width="180px">
                <template slot-scope="scope">
                  {{ scope.row.hasSetPayeePrice ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="是否设置课程方价格" align="center" show-overflow-tooltip prop="hasSetPayerPrice"
                min-width="180px">
                <template slot-scope="scope">
                  {{ scope.row.hasSetPayerPrice ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="是否设置代理价格" align="center" show-overflow-tooltip prop="hasSetAgentPrice"
                min-width="180px">
                <template slot-scope="scope">
                  {{ scope.row.hasSetAgentPrice ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column label="是否结算" align="center" show-overflow-tooltip prop="billState" min-width="180px">
                <template slot-scope="scope">
                  {{ scope.row.billState }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="80px" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="setPersonnel(scope.row)">设置单价</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="55%" center top="2%" @close="doClose">
      <div class="ovy-a" style="height: 720px">
        <!-- 根据morePriceShow判断班级基本信息是否显示 show显示 false不显示 -->
        <div v-show="morePriceShow">
          <h3>班级基本信息</h3>
          <el-form :inline="false" label-width="120px" class="formCell">
            <el-form-item label="班级编码：" prop="projectName">
              <p>{{ Form.projectCode }}</p>
            </el-form-item>
            <el-form-item label="班级名称：" prop="projectName">
              <p>{{ Form.projectName }}</p>
            </el-form-item>
            <el-form-item label="机构名称：" prop="compName">
              <p>{{ Form.compName }}</p>
            </el-form-item>
            <el-form-item label="有效日期：">
              <p>
                {{ Form.startDate | momentDate }} -
                {{ Form.endDate | momentDate }}
              </p>
            </el-form-item>
            <el-form-item label="培训类型：">
              <p>{{ Form.trainTypeNamePath }}</p>
            </el-form-item>
            <el-form-item label="岗位类型：" v-if="Form.postName">
              <p>{{ Form.postName }}</p>
            </el-form-item>
            <el-form-item label="行业类型：" v-if="Form.industryName">
              <p>{{ Form.industryName }}</p>
            </el-form-item>
            <el-form-item label="培训工种：" v-if="Form.occupationName">
              <p>{{ Form.occupationName }}</p>
            </el-form-item>
            <el-form-item label="培训等级：" v-if="Form.trainLevelName">
              <p>{{ Form.trainLevelName }}</p>
            </el-form-item>
            <el-form-item label="培训人数：">
              <p>{{ Form.projectPeople }}人</p>
            </el-form-item>
            <el-form-item label="行政区划：">
              <p>{{ Form.areaNamePath }}</p>
            </el-form-item>
            <el-form-item label="总课时：">
              <p>{{ Form.lessonNum }}</p>
            </el-form-item>
            <el-form-item label="公共总课时：" v-if="Form.publicKpointNum">
              <p>{{ Form.publicKpointNum }}</p>
            </el-form-item>
            <el-form-item label="自有学时：" v-if="Form.kpointSelf">
              <p>{{ Form.kpointSelf }}</p>
            </el-form-item>
            <el-form-item label="培训方式：">
              <p>
                {{ $setDictionary("PROJECT_TRAIN_METHOD", Form.trainMethod) }}
              </p>
            </el-form-item>
          </el-form>
          <el-divider></el-divider>
        </div>
        <h3>收款单价设置</h3>
        <h4 style="display: flex; padding: 1rem" v-if="ruleForm.collectionContracts">
          <p style="min-width: 5rem; line-height: 30px">收款合同:</p>
          <div style="margin-left: 20px">
            <p style="line-height: 30px" v-for="(item, index) in ruleForm.collectionContracts" :key="index">
              有效期：{{ item.startDate | momentDate }} -
              {{ item.endDate | momentDate }} &nbsp;&nbsp; 附件：
              {{ item.fileName }}
              <el-button type="text" style="padding: 0 5px" @click="lookContract(item.fileKey)">预览</el-button>
            </p>
          </div>
        </h4>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" style="width: 90%">
          <el-form-item label="是否设置收款价格" prop="hasSetPayeePrice" class="form-item">
            <el-switch :width="20" v-model="ruleForm.hasSetPayeePrice" :active-value="true" :inactive-value="false"
              active-color="#13ce66"></el-switch>
            <span>{{ ruleForm.hasSetPayeePrice ? "是" : "否" }}</span>
          </el-form-item>
          <el-form-item label="收款类型" prop="paymentType" class="form-item" v-if="ruleForm.hasSetPayeePrice">
            <el-radio-group v-model="ruleForm.paymentType" @change="settleStandardTypeChange">
              <el-radio label="10">技术/课程费用分开</el-radio>
              <el-radio label="20">技术/课程费用合计</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 分开 -- 比例收费 -->
          <div style="display: flex; flex-direction: column"
            v-if="ruleForm.paymentType == '10' && ruleForm.hasSetPayeePrice">
            <div style="display: flex">
              <el-form-item prop="platformTechnicalFee" class="form-item">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 6rem">技术服务单价:</span>
                    <el-input placeholder="" v-model="ruleForm.platformTechnicalFee" size="small"
                      oninput="value=value.replace(/[^\d.]/g,'')">
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="courseFee" class="form-item left">
                <div style="display: flex; margin-left: 10px; align-items: center">
                  <span style="min-width: 6rem">课程服务单价:</span>
                  <el-input placeholder="" v-model="ruleForm.courseFee" size="small"
                    oninput="value=value.replace(/[^\d.]/g,'')">
                    <template slot="append">元</template>
                  </el-input>
                </div>
              </el-form-item>
            </div>
          </div>
          <div style="display: flex; flex-direction: column"
            v-if="ruleForm.paymentType == '20' && ruleForm.hasSetPayeePrice">
            <div style="display: flex">
              <el-form-item prop="platformTotalFee" class="form-item">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <span style="min-width: 6rem">平台服务单价:</span>
                    <el-input placeholder="" v-model="ruleForm.platformTotalFee" size="small"
                      oninput="value=value.replace(/[^\d.]/g,'')">
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <el-divider></el-divider>
        <h3>代理单价设置</h3>
        <!-- 单个设置 -->
        <div v-if="morePriceShow">
          <h4 style="display: flex; padding: 1rem" v-if="ruleForm.agentContracts && ruleForm.agentContracts.length > 0">
            <p style="min-width: 5rem; line-height: 30px">代理合同:</p>
            <div style="margin-left: 20px">
              <p style="line-height: 30px" v-for="(item, index) in ruleForm.agentContracts" :key="index">
                有效期：{{ item.startDate | momentDate }} -
                {{ item.endDate | momentDate }} &nbsp;&nbsp; 附件：
                {{ item.fileName }}
                <el-button type="text" style="padding: 0 5px" @click="lookContract(item.fileKey)">预览</el-button>
              </p>
            </div>
          </h4>
        </div>
        <!-- 批量设置 -->
        <div v-else>
          <h4 style="display: flex; padding: 1rem" v-if="ruleForm.agencyContracts">
            <p style="min-width: 5rem; line-height: 30px">代理合同:</p>
            <div style="margin-left: 20px">
              <p style="line-height: 30px" v-for="(item, index) in ruleForm.agencyContracts" :key="index">
                有效期：{{ item.startDate | momentDate }} -
                {{ item.endDate | momentDate }} &nbsp;&nbsp; 附件：
                {{ item.fileName }}
                <el-button type="text" style="padding: 0 5px" @click="lookContract(item.fileKey)">预览</el-button>
              </p>
            </div>
          </h4>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" style="width: 90%">
          <el-form-item label="是否设置代理价格" prop="hasSetAgentPrice" class="form-item">
            <el-switch :width="20" v-model="ruleForm.hasSetAgentPrice" :active-value="true" :inactive-value="false"
              active-color="#13ce66"></el-switch>
            <span>{{ ruleForm.hasSetAgentPrice ? "是" : "否" }}</span>
          </el-form-item>
          <el-form-item label="支付类型" prop="agentSettleStyle" class="form-item" v-if="ruleForm.hasSetAgentPrice">
            <el-radio-group v-model="ruleForm.agentSettleStyle" style="width: calc(100% - 68px);">
              <el-radio 
                v-for="item in agencySettleStyle"
                :key="item.value"
                :label="item.value"
                style="margin-bottom: 10px;"
              >
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <div style="display: flex; flex-direction: column" v-if="ruleForm.hasSetAgentPrice">
            <div style="display: flex">
              <el-form-item prop="agentSettleStandard" class="form-item">
                <div style="display: flex">
                  <div style="display: flex; align-items: center">
                    <el-input placeholder="" v-model="ruleForm.agentSettleStandard" size="small"
                      oninput="value=value.replace(/[^\d.]/g,'')">
                      <template slot="append">
                        <template v-if="ruleForm.agentSettleStyle == '10'">%</template>
                        <template v-else-if="ruleForm.agentSettleStyle == '40'||ruleForm.agentSettleStyle == '70'">元/人/课时</template>
                        <template v-else>元/人</template>
                      </template>
                    </el-input>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <el-divider></el-divider>
        <h3 style="padding-bottom: 1rem">课程单价设置</h3>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" style="width: 90%">
          <el-form-item label="是否设置课程价格" prop="hasSetPayerPrice" class="form-item">
            <el-switch :width="20" v-model="ruleForm.hasSetPayerPrice" :active-value="true" :inactive-value="false"
              active-color="#13ce66"></el-switch>
            <span>{{ ruleForm.hasSetPayerPrice ? "是" : "否" }}</span>
          </el-form-item>
        </el-form>
        <el-table v-if="ruleForm.hasSetPayerPrice" ref="multipleTable" :data="ruleForm.coursePrices" size="small"
          tooltip-effect="dark" :header-cell-style="tableHeader">
          <el-table-column label="序号" align="center" type="index" fixed />
          <el-table-column key="projectName" v-if="!morePriceShow" label="班级名称" align="center"
            :show-overflow-tooltip="true" prop="projectName" />
          <el-table-column label="课程方名称" align="center" :show-overflow-tooltip="true" prop="compName" />
          <el-table-column label="总学时" align="center" :show-overflow-tooltip="true" prop="kpointNum" />
          <el-table-column label="其中公共学时" align="center" :show-overflow-tooltip="true" prop="publicKpointNum" />
          <el-table-column label="单价" align="left" show-overflow-tooltip prop="courseMoney" min-width="120"><template
              slot-scope="scope">
              <el-input size="small" v-model="scope.row.courseMoney" placeholder="请输入" @keyup.native="
                scope.row.courseMoney = zF.oninput2(scope.row.courseMoney, 2)
              "></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" :show-overflow-tooltip="true">
            <div slot-scope="scope">
              <el-button type="text" size="mini" style="padding: 0 5px" :disabled="!scope.row.contractPdfUrl"
                @click="lookContract(scope.row.contractPdfUrl)">查看付款合同</el-button>
            </div>
          </el-table-column>
        </el-table>
        <div style="display: flex; justify-content: center">
          <el-button @click="doClose">取消</el-button>
          <el-button class="bgc-bv" @click="submitForm">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="合同预览" :visible.sync="dialogCert" width="50%" top="2%" center :before-close="closeDownloadCert">
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workOrder/classOwnership",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //班级编码
      projectCode: "",
      //班级名称
      projectName: "",
      billState: "", //是否结算
      startTime: "",
      //机构Id
      compId: "",
      areaId: "",
      areatreeList: [], // 行政区划数据
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      //是否设置收款价格
      IsCollectionPrice: "",
      //是否设置课程方价格
      IsCoursePrice: "",
      //是否有收款合同
      hasCollectionContract: "",
      //是否设置代理价格
      IsAgencyPrice: "",
      //培训类型
      trainTypeId: "",
      //机构名称下拉数据
      companyList: [],
      // 设置单价弹框显示
      dialogVisible: false,
      // 班级基本信息
      Form: {},
      // 设置收款单价设置 && 代理单价设置
      ruleForm: {
        hasSetAgentPrice: false,
        hasSetPayeePrice: false,
        hasSetPayerPrice: false,
      },
      //弹框title
      dialogTitle: "设置班级单价",
      //获取培训类型
      trainTypeList: [],
      dialogCert: false,
      settlementStatusList: [
        {
          label: "已结算",
          value: "20",
        },
        {
          label: "未结算",
          value: "10",
        },
      ],
      // 列表勾选的数据
      multipleTable: [],
      morePriceShow: true, //是否显示班级信息
      projectIdList: [], //勾选班级id数组
      agencySettleStyle:[], //代理商支付类型
    };
  },
  watch: {},
  created() {
    this.getTraintype();
    this.getareatree();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getCodeValue()
    this.getTableHeight();
  },
  methods: {
    //获取码值表 -- 提醒类型
     getCodeValue() {
      const AGENCY_SETTLE_STYLE = this.$setDictionary("AGENCY_SETTLE_STYLE", "list");
      let list = []
      for (const key in AGENCY_SETTLE_STYLE) {
        list.push({
          value: key,
          label: AGENCY_SETTLE_STYLE[key],
        });
      }
      this.agencySettleStyle = list
    },
    //获取培训类型
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((ret) => {
        if (ret.status == "0") {
          this.trainTypeList = ret.data;
        }
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 机构名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query.trim() })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        hasSetPayeePrice: this.IsCollectionPrice,
        hasSetPayerPrice: this.IsCoursePrice,
        hasSetAgentPrice: this.IsAgencyPrice,
        hasCollectionContract: this.hasCollectionContract
      };
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.billState) {
        params.billState = this.billState;
      }
      if (this.startTime) {
        params.startDateBegin = this.startTime[0];
        params.startDateEnd = this.startTime[1];
      }

      this.doFetch({
        url: "/biz/projectPrice/fd-new-bill-project-price/pageList",
        params,
        pageNum,
      });
    },

    //设置班级单价
    setPersonnel(row) {
      this.dialogTitle = "设置班级单价";
      this.dialogVisible = true;
      this.morePriceShow = true;
      this.Form = {
        ...row,
      };
      this.$post("/biz/projectPrice/fd-new-bill-project-price/getInfo", {
        projectId: row.projectId,
      })
        .then((ret) => {
          this.ruleForm = {
            hasSetPayeePrice: ret.data.hasSetPayeePrice,
            hasSetPayerPrice: ret.data.hasSetPayerPrice,
            hasSetAgentPrice: ret.data.hasSetAgentPrice,
            coursePrices: ret.data.coursePrices,
            ...ret.data,
          };
        })
        .catch((err) => {
          return;
        });
    },
    //确定
    submitForm() {
      console.log(this.ruleForm.coursePrices)
      if (this.ruleForm.hasSetPayerPrice) {
        for (
          let index = 0;
          index < this.ruleForm.coursePrices.length;
          index++
        ) {
          this.$set(
            this.ruleForm,
            "courseMoney",
            this.ruleForm.coursePrices[index].courseMoney || ""
          );
          if (
            typeof this.ruleForm.coursePrices[index].courseMoney != 'number' && (!this.ruleForm.coursePrices[index].courseMoney ||
              this.ruleForm.coursePrices[index].courseMoney == "")
          ) {
            this.$message({
              message: "课程单价不能为空",
              type: "error",
            });
            return false;
          }
        }
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {};
          let api = "";
          if (this.morePriceShow) {
            api =
              "/biz/projectPrice/fd-new-bill-project-price/saveOrUpdateProjectPrice";
            params = {
              projectId: this.Form.projectId,
              hasSetAgentPrice: this.ruleForm.hasSetAgentPrice,
              hasSetPayeePrice: this.ruleForm.hasSetPayeePrice,
              hasSetPayerPrice: this.ruleForm.hasSetPayerPrice,
              paymentType: this.ruleForm.paymentType,
              agentSettleStyle: this.ruleForm.agentSettleStyle,
              agentSettleStandard: this.ruleForm.agentSettleStandard,
            };
          } else {
            api = "/biz/projectPrice/fd-new-bill-project-price/saveBatch";
            params = {
              list: this.projectIdList,
              hasSetAgentPrice: this.ruleForm.hasSetAgentPrice,
              hasSetPayeePrice: this.ruleForm.hasSetPayeePrice,
              hasSetPayerPrice: this.ruleForm.hasSetPayerPrice,
              paymentType: this.ruleForm.paymentType,
              agentSettleStyle: this.ruleForm.agentSettleStyle,
              agentSettleStandard: this.ruleForm.agentSettleStandard,
            };
          }

          if (this.ruleForm.paymentType == "10") {
            (params.platformTechnicalFee = this.ruleForm.platformTechnicalFee),
              (params.courseFee = this.ruleForm.courseFee);
          } else {
            params.platformTotalFee = this.ruleForm.platformTotalFee;
          }
          params.coursePriceDTOS = this.ruleForm.coursePrices;
          this.$post(api, params).then((ret) => {
            if (ret.status == "0") {
              this.doClose();
              this.$refs.tableData.clearSelection();
              this.getData(-1);
            }
          });
        } else {
          return false;
        }
      });
    },
    doClose() {
      this.ruleForm = {};
      this.$refs["ruleForm"].resetFields();
      this.dialogVisible = false;
    },
    //预览收款合同
    lookContract(url) {
      this.dialogCert = true;
      this.$nextTick(() => {
        pdf.embed(url, "#pdf-cert");
      });
    },
    // 导出
    exportClassPrice() {
      const params = {
        hasSetPayeePrice: this.IsCollectionPrice,
        hasSetPayerPrice: this.IsCoursePrice,
        hasSetAgentPrice: this.IsAgencyPrice,
        hasCollectionContract: this.hasCollectionContract
      };
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.billState) {
        params.billState = this.billState;
      }
      if (this.startTime) {
        params.startDateBegin = this.startTime[0];
        params.startDateEnd = this.startTime[1];
      }
      this.$post(
        "/biz/projectPrice/fd-new-bill-project-price/exportPageList",
        params
      ).then((ret) => {
        window.open(ret.data);
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    // 勾选列表数据
    checkListData(rowArr) {
      this.multipleTable = rowArr;
    },
    //批量设置价格
    setClassPriceMore() {
      if (this.multipleTable.length > 0) {
        this.projectIdList = this.multipleTable.map((t) => {
          return t.projectId;
        });
        let compIds = [];
        compIds = this.multipleTable.map((t) => {
          return t.compId;
        });
        if (new Set(this.multipleTable.map((t) => t.compId)).size > 1) {
          this.$message({
            message: "不同机构不能批量设置价格",
            type: "error",
          });
        } else {
          this.dialogVisible = true;
          this.morePriceShow = false;
          this.$post(
            "/biz/projectPrice/fd-new-bill-project-price/getInfoBatch",
            {
              compId: compIds[0],
              list: this.projectIdList,
            }
          )
            .then((ret) => {
              this.ruleForm = {
                hasSetPayeePrice: false,
                hasSetPayerPrice: false,
                hasSetAgentPrice: false,
                ...ret.data,
              };
            })
            .catch((err) => {
              return;
            });
        }
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.formCell {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 50%;
    margin-bottom: 0.5rem;
  }
}

.priceManager {
  .itemLabel {
    min-width: 7.5rem;
  }
}
</style>
